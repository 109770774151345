var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"vh-100 d-flex flex-colum justify-content-center align-items-center",attrs:{"fluid":""}},[_c('b-row',{staticClass:"container-padding"},[_c('b-col',{staticClass:"min-h-125px max-h-125px d-flex align-items-center justify-content-center border-bottom",style:({
        marginBottom: '24px'
      }),attrs:{"cols":"12","order":"2","order-md":"2"}},[_c('h3',{staticClass:"text-center text-primary font-weight-bolder container-title"},[_vm._v(" Service Driver and Vehicle Reservation System ")])]),_c('b-col',{staticClass:"d-flex flex-column justify-content-center align-items-center pt-5 py-md-3",staticStyle:{"user-select":"none","-ms-user-select":"none","-webkit-user-select":"none"},attrs:{"cols":"12","md":"6","order":"1","order-md":"2"}},[_c('img',{staticClass:"background-left",attrs:{"alt":"wave","src":require("../../assets/images/wave.png")}}),_c('img',{staticClass:"vehicle",attrs:{"alt":"vehicle","src":require('@/assets/images/front.png')}})]),_c('b-col',{staticClass:"d-flex flex-column justify-content-md-center align-items-center",attrs:{"cols":"12","md":"6","order":"3"}},[_c('img',{staticClass:"background-right",attrs:{"alt":"wave","src":require("../../assets/images/wave.png")}}),_c('ValidationObserver',{ref:"signinForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('form',{staticStyle:{"max-width":"400px"},attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSignIn)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-start font-weight-bolder"},[_vm._v(" Sign In ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"email","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":"emailInput"}},[_vm._v(" Email ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials.email),expression:"credentials.email"}],class:{
                      'form-control mb-0': true,
                      'is-invalid': errors.length > 0
                    },attrs:{"id":"emailInput","type":"email","aria-describedby":"emailHelp","placeholder":"enter email here","disabled":_vm.state.busy,"autocomplete":"off"},domProps:{"value":(_vm.credentials.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credentials, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"password","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Password","label-for":"password","label-class":"font-weight-bold"}},[_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : ''},[_c('b-input',{attrs:{"id":"password","placeholder":"enter password here","autocomplete":"off","type":_vm.passwordType,"state":errors.length > 0 ? false : null,"disabled":_vm.state.busy},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"pl-2 pr-1 text-dark",staticStyle:{"border-radius":"0px 5px 5px 0px","background-color":"var(--iq-body-color)","border":"1px solid #ccc","border-left":"0px solid transparent"},attrs:{"variant":"warning","disabled":_vm.state.busy},on:{"click":_vm.togglePassword}},[(_vm.state.visible_password)?_c('i',{staticClass:"ri-eye-line h5"}):_c('i',{staticClass:"ri-eye-off-line h5"})])],1)],1),(errors.length > 0)?_c('div',{staticClass:"invalid-feedback",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"px-2 pt-2"},[_c('b-overlay',{staticClass:"col-12 px-1",attrs:{"rounded":"","opacity":"0.1","spinner-variant":"warning","spinner-small":"","show":_vm.state.local}},[_c('b-button',{staticClass:"w-100",attrs:{"size":"sm","type":"submit","variant":"success","disabled":_vm.state.busy}},[_vm._v(" Sign In ")])],1),_c('b-col',{staticClass:"text-divider"},[_c('span',[_vm._v("  or sign in with")])]),_c('b-overlay',{staticClass:"col-12 px-1",attrs:{"rounded":"","opacity":"0.1","spinner-variant":"success","spinner-small":"","show":_vm.state.sso}},[_c('b-button',{staticClass:"w-100",attrs:{"size":"sm","type":"button","variant":"warning","disabled":_vm.state.busy},on:{"click":_vm.onSignInWithAljaySSO}},[_vm._v(" Aljay SSO ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }