<template>
  <b-container
    fluid
    class="vh-100 d-flex flex-colum justify-content-center align-items-center"
  >

    <b-row class="container-padding">
      <b-col
        cols="12"
        class="min-h-125px max-h-125px d-flex align-items-center justify-content-center border-bottom"
        order="2"
        order-md="2"
        :style="{
          marginBottom: '24px'
        }"
      >
        <h3 class="text-center text-primary font-weight-bolder container-title">
          Service Driver and Vehicle Reservation System
        </h3>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex flex-column justify-content-center align-items-center pt-5 py-md-3"
        order="1"
        order-md="2"
        style="user-select: none; -ms-user-select: none; -webkit-user-select: none;"
      >
        <img
          alt="wave"
          src="../../assets/images/wave.png"
          class="background-left"
        >
        <img
          alt="vehicle"
          :src="require('@/assets/images/front.png')"
          class="vehicle"
        >
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex flex-column justify-content-md-center align-items-center"
        order="3"
      >
        <img
          alt="wave"
          src="../../assets/images/wave.png"
          class="background-right"
        >
        <ValidationObserver
          ref="signinForm"
          v-slot="{ handleSubmit }"
        >
          <form
            novalidate
            style="max-width: 400px;"
            @submit.prevent="handleSubmit(onSignIn)"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-3"
              >
                <h5 class="text-start font-weight-bolder">
                  Sign In
                </h5>
              </b-col>
              <b-col
                cols="12"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="email"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group>
                    <label
                      for="emailInput"
                      class="font-weight-bolder"
                    >
                      Email
                    </label>
                    <input
                      id="emailInput"
                      v-model="credentials.email"
                      type="email"
                      :class="{
                        'form-control mb-0': true,
                        'is-invalid': errors.length > 0
                      }
                      "
                      aria-describedby="emailHelp"
                      placeholder="enter email here"
                      :disabled="state.busy"
                      autocomplete="off"
                    >
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="password"
                  name="password"
                  rules="required"
                >
                  <b-form-group
                    label="Password"
                    label-for="password"
                    label-class="font-weight-bold"
                    class="mb-3"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid' : ''"
                    >
                      <b-input
                        id="password"
                        v-model="credentials.password"
                        placeholder="enter password here"
                        autocomplete="off"
                        :type="passwordType"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <b-input-group-append>
                        <b-button
                          class="pl-2 pr-1 text-dark"
                          variant="warning"
                          style="border-radius: 0px 5px 5px 0px; background-color: var(--iq-body-color); border: 1px solid #ccc; border-left: 0px solid transparent;"
                          :disabled="state.busy"
                          @click="togglePassword"
                        >
                          <i
                            v-if="state.visible_password"
                            class="ri-eye-line h5"
                          />
                          <i
                            v-else
                            class="ri-eye-off-line h5"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row class="px-2 pt-2">

              <b-overlay
                class="col-12 px-1"
                rounded
                opacity="0.1"
                spinner-variant="warning"
                spinner-small
                :show="state.local"
              >
                <b-button
                  size="sm"
                  type="submit"
                  class="w-100"
                  variant="success"
                  :disabled="state.busy"
                >
                  Sign In
                </b-button>
              </b-overlay>

              <b-col class="text-divider">
                <span>&nbsp; or sign in with</span>
              </b-col>

              <b-overlay
                class="col-12 px-1"
                rounded
                opacity="0.1"
                spinner-variant="success"
                spinner-small
                :show="state.sso"
              >
                <b-button
                  size="sm"
                  type="button"
                  class="w-100"
                  variant="warning"
                  :disabled="state.busy"
                  @click="onSignInWithAljaySSO"
                >
                  Aljay SSO
                </b-button>
              </b-overlay>

            </b-row>
          </form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SSharedAuth, SSharedSSO } from '@/services'
import { Oauth2 } from 'universal-social-auth'

export default {
  name: 'SharedSignIn',

  middleware: ['guest'],

  metaInfo () {
    return {
      title: 'Sign In'
    }
  },

  data: () => ({
    state: {
      busy: false,
      local: false,
      sso: false,
      visible_password: false
    },
    credentials: {
      username: null,
      password: null
    },
    Oauth2
  }),

  computed: {
    passwordType () {
      return this.state.visible_password ? 'text' : 'password'
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    togglePassword () {
      this.state.visible_password = !this.state.visible_password
    },

    async saveAndGetCurrentUser (token) {
      this.$store.dispatch('auth/saveToken', token)
      return new Promise((resolve, reject) => {
        SSharedAuth.getCurrentUser().then(
          ({ data }) => {
            this.$store.dispatch('auth/fetchUserSuccess', data)
            const user = this.$store.getters['auth/user']
            this.$router.replace({
              name: user ? `${user.role}.dashboard` : 'dashboard'
            }, () => {
              resolve(data)
            })
          }
        ).catch(
          error => {
            this.$store.dispatch('auth/fetchUserFailure')
            reject(error)
          }
        )
      })
    },

    async onSignIn () {
      await this.$refs.signinForm.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = this.state.local = true
            SSharedAuth.signIn(this.credentials).then(
              ({ data: { token } }) => {
                return this.saveAndGetCurrentUser(token)
              }
            ).finally(() => {
              this.state.busy = this.state.local = false
            })
          }
        }
      )
    },

    async onSignInWithAljaySSO (event) {
      event.preventDefault()
      this.state.busy = this.state.sso = true
      this.$Oauth.authenticate('oauth2', this.Oauth2).then(
        authorized => {
          SSharedSSO.post(authorized).then(
            ({ data: { token } }) => {
              return this.saveAndGetCurrentUser(token)
            }
          ).finally(() => {
            this.state.busy = this.state.sso = false
          })
        }
      )
    }
  }
}
</script>

<style lang="css" scoped>
.container-padding {
  padding: 40px 0
}

.container-title {
  font-size: 24px;
}

.vehicle {
  width: 50vw;
}

.background-left {
  position: fixed;
  height: 20vh;
  width: 50vw;
  bottom: 0;
  left: 0;
  z-index: -10;
  /* display: none; */
}

.background-right {
  position: fixed;
  height: 30vh;
  width: 80vw;
  transform: rotate(180deg);
  top: 0;
  right: 0;
  z-index: -10;
  /* display: none; */
}

@media screen and (min-width: 1024px) {

  .container-title {
    font-size: 35px;
  }

  .container-padding {
    padding: 100px 0
  }

  .vehicle {
    width: 30vw;
  }

  .background-left {
    height: 70vh;
    width: 50vw;
    display: block;
  }

  .background-right {
    height: 35vh;
    width: 25vw;
    display: block;
  }
}

.text-divider {
  margin: 2em 0;
  line-height: 0;
  text-align: center;
  color: gray;
  padding: 0px 5px;
}

.text-divider span {
  background-color: #f8f7fd;
  padding: 0 0.5em;
}

.text-divider:before {
  content: " ";
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid gray;
  border-bottom: 1px solid #f8f7fd;
}

.text-divider span:before  {
  content: "";
  display: block;
  margin-top: -3px;
}

.text-divider span:after  {
  display: block;
  margin-top: -3px;
}
</style>
